import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import '../styles/Pricing.scss';
import { Link } from 'react-router-dom';

function Pricing() {

  return (
    <>
      <div id='navBG'></div>
      <div id='gateway' style={{ background: '#b44afb' }}>
        <Nav />

        <div id='pricing'>
          <h1>Affordable &amp; Simple Pi APIs</h1>

          <i id='notice'>
            <b>Notice:</b> Subscriptions transactions are coming soon.
          </i>

          <div id='plans'>
            <div className='plan'>
              <h2>There's No Monthly Cost!</h2>
              <i>1.2% transaction fee</i>
              <div className='planOverview'>
                <p><i className='fas fa-check'></i> Unlimited transactions </p>
                <p><i className='fas fa-check'></i> No max transaction value</p>
                <p><i className='fas fa-check'></i> Use our signature on-chain transactions</p>
                <p><i className='fas fa-check'></i> Includes low-code button</p>
                <p><i className='fas fa-check'></i> Includes subscription transactions</p>
              </div>
              <Link to='/register'>SIGN UP</Link>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id='footerBG'></div>
    </>
  );
}

export default Pricing;