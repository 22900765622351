import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/whiteLogo.png';

export default function Nav() {
  const [nav, setNav] = useState(false);

  return (
    <>
      <nav className={nav === true && 'navWhite'}>
        {
          window.innerWidth > 700 ?
            <>
              <Link to='/'>Overview</Link>
              <a href='https://docs.piecard.app'>Developers</a>
              <Link to='/pricing'>Pricing</Link>
              {/* <Link to='/faq'>FAQs</Link> */}
              {
                !sessionStorage.userSession ?
                  <Link id='dashboardBtn' to='/register'>Sign in</Link> :
                  <Link id='dashboardBtn' to='/dashboard'>Dashboard</Link>
              }
            </> :
            <>
              <i className={`fas fa-bars ${nav === false && 'active'}`} id="openNav" onClick={() => setNav(true)}></i>
              <i className={`fas fa-times ${nav === true && 'active'}`} id="closeNav" onClick={() => setNav(false)}></i>
              <div className={`sideNav ${nav === true && 'sideNavActive'}`}>
                <Link to='/'>Overview</Link><br />
                <a href='https://docs.piecard.app'>Developers</a><br />
                <Link to='/pricing'>Pricing</Link><br />
                {/* <Link to='/faq'>FAQs</Link><br /> */}
                {
                  !sessionStorage.userSession ?
                    <Link id='dashboardBtn' to='/register'>Sign in</Link> :
                    <Link id='dashboardBtn' to='/dashboard'>Dashboard</Link>
                }
              </div>
            </>
        }
        <img id='navLogo' src={logo} alt='logo' onClick={() => window.location.href = '/'} />
      </nav>
      {nav === true && <div id='tint' onClick={() => setNav(false)}></div>}
    </>
  );
}