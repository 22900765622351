import React from "react";
import '../styles/Footer.scss';

export default function Footer() {
  return (
    <div id='footer'>
      <div id='footerLinks'>
        <a href='/docs'>Docs</a><br />
        <a href='/pricing'>Pricing</a><br />
        <a href='/about'>About</a><br />
      </div>

      <div id='footerBottom'>
        <p>Copyright © 2022-24</p>

        <div id='socials'>
          <a href='https://twitter.com/pi_ecard'><i className="fab fa-twitter"></i></a>
          <a href='https://www.facebook.com/Pi-Webinars-106614382056009'><i className="fab fa-facebook"></i></a>
          <a href='https://www.instagram.com/pi_ecard'><i className="fab fa-instagram"></i></a>
          <a href='https://www.linkedin.com/company/77668347'><i className="fab fa-linkedin"></i></a>
        </div>
      </div>
    </div>
  )
}